import React from 'react';
import './About.css'; // Import the CSS file

const About = () => {
  return (
    <div className="center-div">
      <h1 className='aboutJinsei7'>Transparent, Secure, Perminence in AI Automation.</h1>
      <div className='aboutJinsei'>
      Jinsei.ai's design is centered around flexibility, from creating multi-silo AI transformers for long analysis tasks, to licensing end-to-end automated processes, to providing model code images for easy integration into ERP systems, Jinsei Corp delivers powerful automation right into your lap. 
      </div>
      <div className='aboutJinsei'>
      Utilizing bleeding edge in-house server infrastructure, Jinsei Corp provides best in class automated process hosting.
      </div>
    
      <div className='aboutJinsei'>
      Pushing us to the front, Jinsei API supports publishing and licensing AI models.  Set to yield highly powerful automation workflows, Jinsei.ai empowers businesses and individuals with AI collaboration abilities never seen before.
      </div>
 
      <div className='aboutJinsei'>
      Enterprise operators develop their own transformer models with previous work, creating full workflows by sharing transformers internally, leveraging the Jinsei.ai public marketplace, and licensing to third parties.
      </div>
   
      <div className='aboutJinsei'>
      Jinsei.ai is the future AI automation hub for individuals and businesses all around Earth. 
      </div>
  
      <div className='aboutJinsei'>
      The Control Panel's workflows are live on our Youtube channel: <a href='https://www.youtube.com/@Jinsei-AI'>Watch</a> 
      </div>

    </div>
  );
};

export default About;
