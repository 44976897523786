// services.js
export const SyntaxServices = [
    {
      title: 'Private Equity',
      description: "Description: K1 Aggregators, Entity Tracking, XLSX, CSV, Form 10-K, 10-Q, anything Private Equity ",
      trainingData: 'Training Data: Sample training data for XML/CSV/JSON Sequence-to-Sequence Sheets Transformer.',
      intakeData: 'Intake Data: Sample intake data for XML/CSV/JSON Sequence-to-Sequence Sheets Transformer.',
      performanceData: 'Performance Data: Sample performance data for XML/CSV/JSON Sequence-to-Sequence Sheets Transformer.',
      integrationNotes: 'Integration Notes: Standardized data format for integration into Baker Tilly tool suite.',
    },
    {
      title: 'Web 3',
      description: "Description: Smart contract transaction classifiers",
      trainingData: 'Training Data: Sample training data for XML/CSV/JSON Sequence-to-Sequence Sheets Transformer.',
      intakeData: 'Intake Data: Sample intake data for XML/CSV/JSON Sequence-to-Sequence Sheets Transformer.',
      performanceData: 'Performance Data: Sample performance data for XML/CSV/JSON Sequence-to-Sequence Sheets Transformer.',
      integrationNotes: 'Integration Notes: Standardized data format for integration into Baker Tilly tool suite.',
    },
    {
      title: 'Capitlization',
      description: "Description: IFRS 38 cap rules, cap analysis",
      trainingData: 'Training Data: Sample training data for XML/CSV/JSON Sequence-to-Sequence Sheets Transformer.',
      intakeData: 'Intake Data: Sample intake data for XML/CSV/JSON Sequence-to-Sequence Sheets Transformer.',
      performanceData: 'Performance Data: Sample performance data for XML/CSV/JSON Sequence-to-Sequence Sheets Transformer.',
      integrationNotes: 'Integration Notes: Standardized data format for integration into Baker Tilly tool suite.',
    },
    {
      title: 'SBRM (OMG)',
      description: "Description: populate SBRM with aspects and facts.",
      trainingData: 'Training Data: Sample training data for XML/CSV/JSON Sequence-to-Sequence Sheets Transformer.',
      intakeData: 'Intake Data: Sample intake data for XML/CSV/JSON Sequence-to-Sequence Sheets Transformer.',
      performanceData: 'Performance Data: Sample performance data for XML/CSV/JSON Sequence-to-Sequence Sheets Transformer.',
      integrationNotes: 'Integration Notes: Standardized data format for integration into Baker Tilly tool suite.',
    },
    {
      title: 'ACTUS',
      description: "Description: all encompassing financial product modeler (about 100 of every fin product today)",
      trainingData: 'Training Data: Sample training data for XML/CSV/JSON Sequence-to-Sequence Sheets Transformer.',
      intakeData: 'Intake Data: Sample intake data for XML/CSV/JSON Sequence-to-Sequence Sheets Transformer.',
      performanceData: 'Performance Data: Sample performance data for XML/CSV/JSON Sequence-to-Sequence Sheets Transformer.',
      integrationNotes: 'Integration Notes: Standardized data format for integration into Baker Tilly tool suite.',
    },
 
  ];
  
  export const AuthorityServices = [
    {
      title: 'SEC EDGAR',
      description: "Upload filings, retrieve filings",
      trainingData: 'EdgarPack Training Data: Sample training data for XML/CSV/JSON Sequence-to-Sequence Sheets Transformer.',
      intakeData: 'Intake Data: Sample intake data for XML/CSV/JSON Sequence-to-Sequence Sheets Transformer.',
      performanceData: 'Performance Data: Sample performance data for XML/CSV/JSON Sequence-to-Sequence Sheets Transformer.',
      integrationNotes: 'Integration Notes: Standardized data format for integration into Baker Tilly tool suite.',
    },
    {
      title: 'ESMA',
      description: "Description: populate euro web3 asset tracking filing specs' aspects and facts, via ixbrl whitepapers.",
      trainingData: 'Training Data: Sample training data for XML/CSV/JSON Sequence-to-Sequence Sheets Transformer.',
      intakeData: 'Intake Data: Sample intake data for XML/CSV/JSON Sequence-to-Sequence Sheets Transformer.',
      performanceData: 'Performance Data: Sample performance data for XML/CSV/JSON Sequence-to-Sequence Sheets Transformer.',
      integrationNotes: 'Integration Notes: Standardized data format for integration into Baker Tilly tool suite.',
    },
    {
      title: 'ESMA Web3 MiCa-ViCa',
      description: "Description: populate euro web3 asset tracking filing specs' aspects and facts, via ixbrl whitepapers.",
      trainingData: 'Training Data: Sample training data for XML/CSV/JSON Sequence-to-Sequence Sheets Transformer.',
      intakeData: 'Intake Data: Sample intake data for XML/CSV/JSON Sequence-to-Sequence Sheets Transformer.',
      performanceData: 'Performance Data: Sample performance data for XML/CSV/JSON Sequence-to-Sequence Sheets Transformer.',
      integrationNotes: 'Integration Notes: Standardized data format for integration into Baker Tilly tool suite.',
    },
  ];
  

  

  export const PE = [
    {
      title: '[Private] Footnote A Maker',
      description: "By Bob Smith, [Private]",
      trainingData: 'EdgarPack Training Data: Sample training data for XML/CSV/JSON Sequence-to-Sequence Sheets Transformer.',
      intakeData: 'Intake Data: Sample intake data for XML/CSV/JSON Sequence-to-Sequence Sheets Transformer.',
      performanceData: 'Performance Data: Sample performance data for XML/CSV/JSON Sequence-to-Sequence Sheets Transformer.',
      integrationNotes: 'Description: Standardized data format for integration into Baker Tilly tool suite.',
    },
    {
      title: '[Private] Footnote B Maker',
      description: "Unrelated Business Income Tax Note By Bob Smith, [Private]",
      trainingData: 'EdgarPack Training Data: Sample training data for XML/CSV/JSON Sequence-to-Sequence Sheets Transformer.',
      intakeData: 'Intake Data: Sample intake data for XML/CSV/JSON Sequence-to-Sequence Sheets Transformer.',
      performanceData: 'Performance Data: Sample performance data for XML/CSV/JSON Sequence-to-Sequence Sheets Transformer.',
      integrationNotes: 'Description: Standardized data format for integration into Baker Tilly tool suite.',
    },
    {
      title: 'Account Transactions [Wells Fago] to Entity Cash Flow Statement',
      description: "By Xgroup, [Public]",
      trainingData: 'EdgarPack Training Data: Sample training data for XML/CSV/JSON Sequence-to-Sequence Sheets Transformer.',
      intakeData: 'Intake Data: Sample intake data for XML/CSV/JSON Sequence-to-Sequence Sheets Transformer.',
      performanceData: 'Performance Data: Sample performance data for XML/CSV/JSON Sequence-to-Sequence Sheets Transformer.',
      integrationNotes: 'Description: Standardized data format for integration into Baker Tilly tool suite.',
    },
    {
      title: 'Fund equity balance sheet [Ygroup] to LEI & K1 ',
      description: "By Ygroup, [Licensing Required]",
      trainingData: 'EdgarPack Training Data: Sample training data for XML/CSV/JSON Sequence-to-Sequence Sheets Transformer.',
      intakeData: 'Intake Data: Sample intake data for XML/CSV/JSON Sequence-to-Sequence Sheets Transformer.',
      performanceData: 'Performance Data: Sample performance data for XML/CSV/JSON Sequence-to-Sequence Sheets Transformer.',
      integrationNotes: 'Description : Standardized data format for integration into Baker Tilly tool suite.',
    },
    {
      title: 'Insurance Industry K1 Footnote Maker [KPMG Spec 1]',
      description: "By Zgroup, [Public]",
      trainingData: 'EdgarPack Training Data: Sample training data for XML/CSV/JSON Sequence-to-Sequence Sheets Transformer.',
      intakeData: 'Intake Data: Sample intake data for XML/CSV/JSON Sequence-to-Sequence Sheets Transformer.',
      performanceData: 'Performance Data: Sample performance data for XML/CSV/JSON Sequence-to-Sequence Sheets Transformer.',
      integrationNotes: 'Integration Notes: Standardized data format for integration into Baker Tilly tool suite.',
    },
    {
      title: 'Fund equity balance sheet [Ygroup] to LEI & K1 [ESMA & Jinsei Spec]',
      description: "By Ygroup, Licensing Required",
      trainingData: 'EdgarPack Training Data: Sample training data for XML/CSV/JSON Sequence-to-Sequence Sheets Transformer.',
      intakeData: 'Intake Data: Sample intake data for XML/CSV/JSON Sequence-to-Sequence Sheets Transformer.',
      performanceData: 'Performance Data: Sample performance data for XML/CSV/JSON Sequence-to-Sequence Sheets Transformer.',
      integrationNotes: 'Description: Standardized data format for integration into Baker Tilly tool suite.',
    },
    {
      title: 'Fund equity balance sheet [Ygroup] to LEI & K1 [ESMA & Jinsei Spec]',
      description: "By Ygroup, Licensing Required",
      trainingData: 'EdgarPack Training Data: Sample training data for XML/CSV/JSON Sequence-to-Sequence Sheets Transformer.',
      intakeData: 'Intake Data: Sample intake data for XML/CSV/JSON Sequence-to-Sequence Sheets Transformer.',
      performanceData: 'Performance Data: Sample performance data for XML/CSV/JSON Sequence-to-Sequence Sheets Transformer.',
      integrationNotes: 'Integration Notes: Standardized data format for integration into Baker Tilly tool suite.',
    },
    {
      title: 'Fund equity balance sheet [Ygroup] to LEI & K1 [ESMA & Jinsei Spec]',
      description: "By Ygroup, Licensing Required",
      trainingData: 'EdgarPack Training Data: Sample training data for XML/CSV/JSON Sequence-to-Sequence Sheets Transformer.',
      intakeData: 'Intake Data: Sample intake data for XML/CSV/JSON Sequence-to-Sequence Sheets Transformer.',
      performanceData: 'Performance Data: Sample performance data for XML/CSV/JSON Sequence-to-Sequence Sheets Transformer.',
      integrationNotes: 'Integration Notes: Standardized data format for integration into Baker Tilly tool suite.',
    },
    {
      title: 'Fund equity balance sheet [Ygroup] to LEI & K1 [ESMA & Jinsei Spec]',
      description: "By Ygroup, Licensing Required",
      trainingData: 'EdgarPack Training Data: Sample training data for XML/CSV/JSON Sequence-to-Sequence Sheets Transformer.',
      intakeData: 'Intake Data: Sample intake data for XML/CSV/JSON Sequence-to-Sequence Sheets Transformer.',
      performanceData: 'Performance Data: Sample performance data for XML/CSV/JSON Sequence-to-Sequence Sheets Transformer.',
      integrationNotes: 'Integration Notes: Standardized data format for integration into Baker Tilly tool suite.',
    },
    {
      title: 'Fund equity balance sheet [Ygroup] to LEI & K1 [ESMA & Jinsei Spec]',
      description: "By Ygroup, Licensing Required",
      trainingData: 'EdgarPack Training Data: Sample training data for XML/CSV/JSON Sequence-to-Sequence Sheets Transformer.',
      intakeData: 'Intake Data: Sample intake data for XML/CSV/JSON Sequence-to-Sequence Sheets Transformer.',
      performanceData: 'Performance Data: Sample performance data for XML/CSV/JSON Sequence-to-Sequence Sheets Transformer.',
      integrationNotes: 'Integration Notes: Standardized data format for integration into Baker Tilly tool suite.',
    },
    {
      title: 'Fund equity balance sheet [Ygroup] to LEI & K1 [ESMA & Jinsei Spec]',
      description: "By Ygroup, Licensing Required",
      trainingData: 'EdgarPack Training Data: Sample training data for XML/CSV/JSON Sequence-to-Sequence Sheets Transformer.',
      intakeData: 'Intake Data: Sample intake data for XML/CSV/JSON Sequence-to-Sequence Sheets Transformer.',
      performanceData: 'Performance Data: Sample performance data for XML/CSV/JSON Sequence-to-Sequence Sheets Transformer.',
      integrationNotes: 'Integration Notes: Standardized data format for integration into Baker Tilly tool suite.',
    },
    {
        title: 'K1 Report Pack [Deloitte] given Entity Cash Flow Statements [jinsei standard format] ',
        description: "Upload filings, retrieve filings",
        trainingData: 'EdgarPack Training Data: Sample training data for XML/CSV/JSON Sequence-to-Sequence Sheets Transformer.',
        intakeData: 'Intake Data: Sample intake data for XML/CSV/JSON Sequence-to-Sequence Sheets Transformer.',
        performanceData: 'Performance Data: Sample performance data for XML/CSV/JSON Sequence-to-Sequence Sheets Transformer.',
        integrationNotes: 'Integration Notes: Standardized data format for integration into Baker Tilly tool suite.',
      },
      {
        title: 'K1 Report Pack [Deloitte] given Entity Cash Flow Statements [jinsei standard format] ',
        description: "Upload filings, retrieve filings",
        trainingData: 'EdgarPack Training Data: Sample training data for XML/CSV/JSON Sequence-to-Sequence Sheets Transformer.',
        intakeData: 'Intake Data: Sample intake data for XML/CSV/JSON Sequence-to-Sequence Sheets Transformer.',
        performanceData: 'Performance Data: Sample performance data for XML/CSV/JSON Sequence-to-Sequence Sheets Transformer.',
        integrationNotes: 'Integration Notes: Standardized data format for integration into Baker Tilly tool suite.',
      },
      {
        title: 'K1 Report Pack [Deloitte] given Entity Cash Flow Statements [jinsei standard format] ',
        description: "Upload filings, retrieve filings",
        trainingData: 'EdgarPack Training Data: Sample training data for XML/CSV/JSON Sequence-to-Sequence Sheets Transformer.',
        intakeData: 'Intake Data: Sample intake data for XML/CSV/JSON Sequence-to-Sequence Sheets Transformer.',
        performanceData: 'Performance Data: Sample performance data for XML/CSV/JSON Sequence-to-Sequence Sheets Transformer.',
        integrationNotes: 'Integration Notes: Standardized data format for integration into Baker Tilly tool suite.',
      },
      {
        title: 'K1 Report Pack [Deloitte] given Entity Cash Flow Statements [jinsei standard format] ',
        description: "Upload filings, retrieve filings",
        trainingData: 'EdgarPack Training Data: Sample training data for XML/CSV/JSON Sequence-to-Sequence Sheets Transformer.',
        intakeData: 'Intake Data: Sample intake data for XML/CSV/JSON Sequence-to-Sequence Sheets Transformer.',
        performanceData: 'Performance Data: Sample performance data for XML/CSV/JSON Sequence-to-Sequence Sheets Transformer.',
        integrationNotes: 'Integration Notes: Standardized data format for integration into Baker Tilly tool suite.',
      },
      {
        title: 'K1 Report Pack [Deloitte] given Entity Cash Flow Statements [jinsei standard format] ',
        description: "Upload filings, retrieve filings",
        trainingData: 'EdgarPack Training Data: Sample training data for XML/CSV/JSON Sequence-to-Sequence Sheets Transformer.',
        intakeData: 'Intake Data: Sample intake data for XML/CSV/JSON Sequence-to-Sequence Sheets Transformer.',
        performanceData: 'Performance Data: Sample performance data for XML/CSV/JSON Sequence-to-Sequence Sheets Transformer.',
        integrationNotes: 'Integration Notes: Standardized data format for integration into Baker Tilly tool suite.',
      },
      {
        title: 'K1 Report Pack [Deloitte] given Entity Cash Flow Statements [jinsei standard format] ',
        description: "Upload filings, retrieve filings",
        trainingData: 'EdgarPack Training Data: Sample training data for XML/CSV/JSON Sequence-to-Sequence Sheets Transformer.',
        intakeData: 'Intake Data: Sample intake data for XML/CSV/JSON Sequence-to-Sequence Sheets Transformer.',
        performanceData: 'Performance Data: Sample performance data for XML/CSV/JSON Sequence-to-Sequence Sheets Transformer.',
        integrationNotes: 'Integration Notes: Standardized data format for integration into Baker Tilly tool suite.',
      },
  ]

  export const Cap = [
    {
      title: 'Trans america project ifrs 38 stuff flow edK1 Report Pack [Deloitte] given Entity Cash Flow Statements [jinsei standard format] ',
      description: "Upload filings, retrieve filings",
      trainingData: 'EdgarPack Training Data: Sample training data for XML/CSV/JSON Sequence-to-Sequence Sheets Transformer.',
      intakeData: 'Intake Data: Sample intake data for XML/CSV/JSON Sequence-to-Sequence Sheets Transformer.',
      performanceData: 'Performance Data: Sample performance data for XML/CSV/JSON Sequence-to-Sequence Sheets Transformer.',
      integrationNotes: 'Integration Notes: Standardized data format for integration into Baker Tilly tool suite.',
    },
    {
      title: 'Trans america project ifrs 38 stuff flow edK1 Report Pack [Deloitte] given Entity Cash Flow Statements [jinsei standard format] ',
      description: "Upload filings, retrieve filings",
      trainingData: 'EdgarPack Training Data: Sample training data for XML/CSV/JSON Sequence-to-Sequence Sheets Transformer.',
      intakeData: 'Intake Data: Sample intake data for XML/CSV/JSON Sequence-to-Sequence Sheets Transformer.',
      performanceData: 'Performance Data: Sample performance data for XML/CSV/JSON Sequence-to-Sequence Sheets Transformer.',
      integrationNotes: 'Integration Notes: Standardized data format for integration into Baker Tilly tool suite.',
    },
    {
      title: 'Trans america project ifrs 38 stuff flow edK1 Report Pack [Deloitte] given Entity Cash Flow Statements [jinsei standard format] ',
      description: "Upload filings, retrieve filings",
      trainingData: 'EdgarPack Training Data: Sample training data for XML/CSV/JSON Sequence-to-Sequence Sheets Transformer.',
      intakeData: 'Intake Data: Sample intake data for XML/CSV/JSON Sequence-to-Sequence Sheets Transformer.',
      performanceData: 'Performance Data: Sample performance data for XML/CSV/JSON Sequence-to-Sequence Sheets Transformer.',
      integrationNotes: 'Integration Notes: Standardized data format for integration into Baker Tilly tool suite.',
    },
    {
      title: 'Trans america project ifrs 38 stuff flow edK1 Report Pack [Deloitte] given Entity Cash Flow Statements [jinsei standard format] ',
      description: "Upload filings, retrieve filings",
      trainingData: 'EdgarPack Training Data: Sample training data for XML/CSV/JSON Sequence-to-Sequence Sheets Transformer.',
      intakeData: 'Intake Data: Sample intake data for XML/CSV/JSON Sequence-to-Sequence Sheets Transformer.',
      performanceData: 'Performance Data: Sample performance data for XML/CSV/JSON Sequence-to-Sequence Sheets Transformer.',
      integrationNotes: 'Integration Notes: Standardized data format for integration into Baker Tilly tool suite.',
    },
    {
      title: 'Trans america project ifrs 38 stuff flow edK1 Report Pack [Deloitte] given Entity Cash Flow Statements [jinsei standard format] ',
      description: "Upload filings, retrieve filings",
      trainingData: 'EdgarPack Training Data: Sample training data for XML/CSV/JSON Sequence-to-Sequence Sheets Transformer.',
      intakeData: 'Intake Data: Sample intake data for XML/CSV/JSON Sequence-to-Sequence Sheets Transformer.',
      performanceData: 'Performance Data: Sample performance data for XML/CSV/JSON Sequence-to-Sequence Sheets Transformer.',
      integrationNotes: 'Integration Notes: Standardized data format for integration into Baker Tilly tool suite.',
    },
    {
      title: 'Trans america project ifrs 38 stuff flow edK1 Report Pack [Deloitte] given Entity Cash Flow Statements [jinsei standard format] ',
      description: "Upload filings, retrieve filings",
      trainingData: 'EdgarPack Training Data: Sample training data for XML/CSV/JSON Sequence-to-Sequence Sheets Transformer.',
      intakeData: 'Intake Data: Sample intake data for XML/CSV/JSON Sequence-to-Sequence Sheets Transformer.',
      performanceData: 'Performance Data: Sample performance data for XML/CSV/JSON Sequence-to-Sequence Sheets Transformer.',
      integrationNotes: 'Integration Notes: Standardized data format for integration into Baker Tilly tool suite.',
    },
    {
      title: 'Trans america project ifrs 38 stuff flow edK1 Report Pack [Deloitte] given Entity Cash Flow Statements [jinsei standard format] ',
      description: "Upload filings, retrieve filings",
      trainingData: 'EdgarPack Training Data: Sample training data for XML/CSV/JSON Sequence-to-Sequence Sheets Transformer.',
      intakeData: 'Intake Data: Sample intake data for XML/CSV/JSON Sequence-to-Sequence Sheets Transformer.',
      performanceData: 'Performance Data: Sample performance data for XML/CSV/JSON Sequence-to-Sequence Sheets Transformer.',
      integrationNotes: 'Integration Notes: Standardized data format for integration into Baker Tilly tool suite.',
    },
    {
        title: 'Trans amer Bank Account Transactions [Wells Fago] to Entity Cash Flow Statement [jinsei standard format]',
        description: "Upload filings, retrieve filings",
        trainingData: 'EdgarPack Training Data: Sample training data for XML/CSV/JSON Sequence-to-Sequence Sheets Transformer.',
        intakeData: 'Intake Data: Sample intake data for XML/CSV/JSON Sequence-to-Sequence Sheets Transformer.',
        performanceData: 'Performance Data: Sample performance data for XML/CSV/JSON Sequence-to-Sequence Sheets Transformer.',
        integrationNotes: '120 users, 3,400 instantiations',
      },
      {
        title: 'Trans amer Bank Account Transactions [Wells Fago] to Entity Cash Flow Statement [jinsei standard format]',
        description: "Upload filings, retrieve filings",
        trainingData: 'EdgarPack Training Data: Sample training data for XML/CSV/JSON Sequence-to-Sequence Sheets Transformer.',
        intakeData: 'Intake Data: Sample intake data for XML/CSV/JSON Sequence-to-Sequence Sheets Transformer.',
        performanceData: 'Performance Data: Sample performance data for XML/CSV/JSON Sequence-to-Sequence Sheets Transformer.',
        integrationNotes: '120 users, 3,400 instantiations',
      },
      {
        title: 'Trans amer Bank Account Transactions [Wells Fago] to Entity Cash Flow Statement [jinsei standard format]',
        description: "Upload filings, retrieve filings",
        trainingData: 'EdgarPack Training Data: Sample training data for XML/CSV/JSON Sequence-to-Sequence Sheets Transformer.',
        intakeData: 'Intake Data: Sample intake data for XML/CSV/JSON Sequence-to-Sequence Sheets Transformer.',
        performanceData: 'Performance Data: Sample performance data for XML/CSV/JSON Sequence-to-Sequence Sheets Transformer.',
        integrationNotes: '120 users, 3,400 instantiations',
      },
      {
        title: 'Trans amer Bank Account Transactions [Wells Fago] to Entity Cash Flow Statement [jinsei standard format]',
        description: "Upload filings, retrieve filings",
        trainingData: 'EdgarPack Training Data: Sample training data for XML/CSV/JSON Sequence-to-Sequence Sheets Transformer.',
        intakeData: 'Intake Data: Sample intake data for XML/CSV/JSON Sequence-to-Sequence Sheets Transformer.',
        performanceData: 'Performance Data: Sample performance data for XML/CSV/JSON Sequence-to-Sequence Sheets Transformer.',
        integrationNotes: '120 users, 3,400 instantiations',
      },
      {
        title: 'Trans amer Bank Account Transactions [Wells Fago] to Entity Cash Flow Statement [jinsei standard format]',
        description: "Upload filings, retrieve filings",
        trainingData: 'EdgarPack Training Data: Sample training data for XML/CSV/JSON Sequence-to-Sequence Sheets Transformer.',
        intakeData: 'Intake Data: Sample intake data for XML/CSV/JSON Sequence-to-Sequence Sheets Transformer.',
        performanceData: 'Performance Data: Sample performance data for XML/CSV/JSON Sequence-to-Sequence Sheets Transformer.',
        integrationNotes: '120 users, 3,400 instantiations',
      },
      {
        title: 'Trans amer Bank Account Transactions [Wells Fago] to Entity Cash Flow Statement [jinsei standard format]',
        description: "Upload filings, retrieve filings",
        trainingData: 'EdgarPack Training Data: Sample training data for XML/CSV/JSON Sequence-to-Sequence Sheets Transformer.',
        intakeData: 'Intake Data: Sample intake data for XML/CSV/JSON Sequence-to-Sequence Sheets Transformer.',
        performanceData: 'Performance Data: Sample performance data for XML/CSV/JSON Sequence-to-Sequence Sheets Transformer.',
        integrationNotes: '120 users, 3,400 instantiations',
      },
      {
        title: 'Trans amer Bank Account Transactions [Wells Fago] to Entity Cash Flow Statement [jinsei standard format]',
        description: "Upload filings, retrieve filings",
        trainingData: 'EdgarPack Training Data: Sample training data for XML/CSV/JSON Sequence-to-Sequence Sheets Transformer.',
        intakeData: 'Intake Data: Sample intake data for XML/CSV/JSON Sequence-to-Sequence Sheets Transformer.',
        performanceData: 'Performance Data: Sample performance data for XML/CSV/JSON Sequence-to-Sequence Sheets Transformer.',
        integrationNotes: '120 users, 3,400 instantiations',
      },
      {
        title: 'Trans amer Bank Account Transactions [Wells Fago] to Entity Cash Flow Statement [jinsei standard format]',
        description: "Upload filings, retrieve filings",
        trainingData: 'EdgarPack Training Data: Sample training data for XML/CSV/JSON Sequence-to-Sequence Sheets Transformer.',
        intakeData: 'Intake Data: Sample intake data for XML/CSV/JSON Sequence-to-Sequence Sheets Transformer.',
        performanceData: 'Performance Data: Sample performance data for XML/CSV/JSON Sequence-to-Sequence Sheets Transformer.',
        integrationNotes: '120 users, 3,400 instantiations',
      },
      {
        title: 'Trans amer Bank Account Transactions [Wells Fago] to Entity Cash Flow Statement [jinsei standard format]',
        description: "Upload filings, retrieve filings",
        trainingData: 'EdgarPack Training Data: Sample training data for XML/CSV/JSON Sequence-to-Sequence Sheets Transformer.',
        intakeData: 'Intake Data: Sample intake data for XML/CSV/JSON Sequence-to-Sequence Sheets Transformer.',
        performanceData: 'Performance Data: Sample performance data for XML/CSV/JSON Sequence-to-Sequence Sheets Transformer.',
        integrationNotes: '120 users, 3,400 instantiations',
      },
      {
        title: 'Trans amer Bank Account Transactions [Wells Fago] to Entity Cash Flow Statement [jinsei standard format]',
        description: "Upload filings, retrieve filings",
        trainingData: 'EdgarPack Training Data: Sample training data for XML/CSV/JSON Sequence-to-Sequence Sheets Transformer.',
        intakeData: 'Intake Data: Sample intake data for XML/CSV/JSON Sequence-to-Sequence Sheets Transformer.',
        performanceData: 'Performance Data: Sample performance data for XML/CSV/JSON Sequence-to-Sequence Sheets Transformer.',
        integrationNotes: '120 users, 3,400 instantiations',
      },
  ]


  export const CreateTransformer = [
    {
        title: 'create title here',
        type: 'createTransformer',
        description: "Upload filings, retrieve filings",
        trainingData: 'EdgarPack Training Data: Sample training data for XML/CSV/JSON Sequence-to-Sequence Sheets Transformer.',
        intakeData: 'Intake Data: Sample intake data for XML/CSV/JSON Sequence-to-Sequence Sheets Transformer.',
        performanceData: 'Performance Data: Sample performance data for XML/CSV/JSON Sequence-to-Sequence Sheets Transformer.',
        integrationNotes: '120 users, 3,400 instantiations',
      },
  ]